<template>
  <div class="mx-a mb-16">
    <div class="category-wrap">
      <div class="category-header-wrap">결제 동의 설정</div>
      <div class="category-content-wrap">
        <div class="flex w-100">
          <span class="contract-title">자동 결제 동의</span>
          <editor-common :content.sync="autoPay" class="w-100" />
        </div>
        <div class="flex w-100" style="margin-top: 32px">
          <span class="contract-title">결제 취소 동의</span>
          <editor-common :content.sync="payCancel" class="w-100" />
        </div>
        <div class="flex w-100" style="margin-top: 32px">
          <span class="contract-title">해지위약금 동의</span>
          <editor-common :content.sync="cancelPenalty" class="w-100" />
        </div>
      </div>
    </div>
    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="onCancel"
          >취소</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkValid"
          @click="editData"
          >저장</button-common
        >
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "SystemContractAgree",
  mixins: [],
  components: {
    draggable,
  },

  /*** data ***/
  data() {
    return {
      autoPay: "",
      payCancel: "",
      cancelPenalty: "",
      autoDummy: "",
      payDummy: "",
      cancelDummy: "",
      toRoute: {
        path: "",
        meta: "",
      },
      isEdit: false,
    };
  },

  /*** created ***/
  /*** created hook ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    await this.getData();
  },

  // beforeRouteLeave(to, from, next) {
  //   this.toRoute.path = to.fullPath;
  //   this.toRoute.meta = to.meta.id;
  //   if (this.isReset) {
  //     next();
  //   } else {
  //     next(false);
  //     if (!this.isReset) {
  //       this.onPageReset();
  //     }
  //   }
  // },

  /*** computed ***/
  computed: {
    checkValid() {
      return this.autoPay && this.payCancel && this.cancelPenalty;
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      const rs = await this.$axios({
        url: "/admin/system/pay/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.autoPay = rs.resultData.autoPay ? rs.resultData.autoPay : "";
        this.payCancel = rs.resultData.payCancel ? rs.resultData.payCancel : "";
        this.cancelPenalty = rs.resultData.cancelPenalty
          ? rs.resultData.cancelPenalty
          : "";
        this.autoDummy = JSON.parse(JSON.stringify(this.autoPay));
        this.payDummy = JSON.parse(JSON.stringify(this.payCancel));
        this.cancelDummy = JSON.parse(JSON.stringify(this.cancelPenalty));
      } else {
        console.log("결제 동의 조회 실패");
      }
    },
    async editData() {
      const rs = await this.$axios({
        url: "/admin/system/pay/update",
        params: {
          autoPay: this.autoPay,
          payCancel: this.payCancel,
          cancelPenalty: this.cancelPenalty,
        },
      });
      if (rs.result === "SUC") {
        this.isEdit = false;
        await this.openConfirm();
        await this.getData();
      } else {
        console.log("결제 동의 수정 실패");
      }
    },
    async openConfirm() {
      await this.$openAlert(
        {
          bodyTx: "변경된 내용이 저장되었습니다.",
          btnScndNm: "확인",
        },
        this
      );
    },
    onEdit() {
      this.isEdit = true;
    },
    onCancel() {
      this.isEdit = false;
      this.autoPay = JSON.parse(JSON.stringify(this.autoDummy));
      this.payCancel = JSON.parse(JSON.stringify(this.payDummy));
      this.cancelPenalty = JSON.parse(JSON.stringify(this.cancelDummy));
    },
  },
};
</script>

<style scoped lang="scss">
.category-wrap {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #d8dae5;
}
.category-header-wrap {
  width: 100%;
  border-bottom: 1px solid #d8dae5;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.category-content-wrap {
  padding: 50px 32px 80px 32px;
}
.contract-title {
  min-width: 150px;
  color: #828699;
  font-size: 14px;
  font-weight: 500;
}
::v-deep .ql-editor {
  height: 278px !important;
  overflow: auto;
}
</style>
